import { Nav } from '../../components/Nav'
import { Footer } from '../../components/Footer'

function PrivacyPolicy() {
  return (
    <>
      <div className={'nav-container'}>
        <Nav />
      </div>

      <section id={'privacy-policy'}>
        <h1>Privacy Policy</h1>

        <article className={'article1'}>
          <h2>Unbanx Data Protection Notice</h2>

          <p>Effective Date: 30/06/2022 </p>
          <p>Last Reviewed: 05/09/2022</p>
        </article>

        <article className={'article2'}>
          <h2>Who We Are</h2>

          <p>
            Unbanx LLC is a company limited by shares and registered in the
            Republic of Ireland.
          </p>

          <h3>Contact Us</h3>
          <p>
            You can contact us at our main business address: 14 Woodstown Lane,
            Knocklyon, Dublin 16, D16NH30.
          </p>
          <p>
            Our Data Protection Officer can be contacted via {''}
            <a href={'mailto:hello@unbanx.me'}>hello@unbanx.me</a>
          </p>

          <h3>Our Values</h3>
          <p>
            We believe in a fair digital economy, and we want to ensure that our
            customers are rewarded for the value they create from the data they
            create by doing their day-to-day banking.
          </p>
          <p>
            We are also aware that data needs to be handled securely and
            transparently. We believe you should have control over your data and
            should have the choice about who has access to your data or who you
            consent to view the data.
          </p>
          <p>
            As part of our ethos of continuous improvement, this Privacy Policy
            and any associated policies or procedures may be changed in the
            future. If we make changes, we will provide notice of any proposed
            changes via email and/or a prominent notice on our service before
            they come into effect.
          </p>

          <h3>What We Do</h3>
          <p>
            The mission of Unbanx is to ensure that our customers are rewarded
            for the value they create from their day-to-day banking data.
          </p>

          <h3>How Do We Obtain Your Personal Data?</h3>
          <p>
            When you use our products or services and apps, we collect
            information about you. This information can be manually entered by
            you or be collected automatically from your smartphone. Other
            information comes from third parties, such as your bank.
          </p>
        </article>

        <article className={'article3'}>
          <h2>Summary:</h2>

          <p>
            We obtain your personal data in one or more of the following ways:
          </p>

          <ul>
            <li>
              <p>
                You directly provide it to us by manually inputting it into one
                of our apps (example: your name, mobile number and when you
                connect your bank account to the Unbanx application) or when you
                enquire with us about our products and services.
              </p>
            </li>

            <li>
              <p>
                You authorise a third party to provide it to us (example:
                Nordigen to collect your banking data using PSD2 open
                standards.)
              </p>
            </li>

            <li>
              <p>
                We automatically record it from your smartphone, when you use
                our products or services, including usage data.
              </p>
            </li>
          </ul>
        </article>

        <article className={'article4'}>
          <h2>Categories of Personal Data We Process</h2>

          <p>
            The specific types of personal data we process about you may vary
            depending on the specific product or service you are using. Examples
            or descriptions of categories of data provided here are not
            exhaustive and are for information purposes. Supplemental
            information will be provided to users for specific contexts where
            necessary.
          </p>

          <table>
            <tbody>
              <tr>
                <td>
                  <p>Identity Information</p>
                </td>

                <td>
                  <p>• First Name</p> <p>• Last Name</p> <p>• Email address</p>
                  <p>• Gender</p>
                  <p>• Year of Birth</p>
                </td>

                <td>
                  <p className={'direct'}>Directly Provided</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Employment Data</p>
                </td>
                <td>
                  <p>• Employment status</p> <p>• Income range</p>
                  <p>• Industry of employment</p>
                </td>
                <td>
                  <p className={'direct'}>Directly Provided</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Banking Data</p>
                </td>
                <td>
                  <p>• Credit cards</p> <p>• Debit Cards</p>
                  <p>• Bank account details</p>
                </td>
                <td>
                  <p className={'nordigen'}>Nordigen</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Transaction id</p>
                </td>
                <td>
                  <p>
                    • Unique identifier of the transaction in a request. It may
                    change between requests
                  </p>
                </td>
                <td>
                  <p className={'nordigen'}>Nordigen</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Time Stamp</p>
                </td>
                <td>
                  <p>• Date the transaction was posted on the account</p>
                </td>
                <td>
                  <p className={'nordigen'}>Nordigen</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Transaction Type</p>
                </td>
                <td>
                  <p>• Type of the transaction</p>
                </td>
                <td>
                  <p className={'nordigen'}>Nordigen</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Transaction category</p>
                </td>
                <td>
                  <p>• Category of the transaction</p>
                </td>
                <td>
                  <p className={'nordigen'}>Nordigen</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Transaction Classification</p>
                </td>
                <td>
                  <p>
                    • Classification of the transaction (array can be empty if
                    classification has not been successfully assigned)
                  </p>
                </td>
                <td>
                  <p className={'nordigen'}>Nordigen</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Merchant name</p>
                </td>
                <td>
                  <p>
                    • Optional (if merchant has been identified) name of the
                    Merchant
                  </p>
                </td>
                <td>
                  <p className={'nordigen'}>Nordigen</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Description</p>
                </td>
                <td>
                  <p>
                    • Original description of the transaction as reported by the
                    Provider
                  </p>
                </td>
                <td>
                  {' '}
                  <p className={'nordigen'}>Nordigen</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Currency</p>
                </td>
                <td>
                  <p>• Alpha -3 Currency code</p>
                </td>
                <td>
                  <p className={'nordigen'}>Nordigen</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>
                    Technical Data[This is indirectly personal data as it can be
                    associated to your device]
                  </p>
                </td>

                <td>
                  <p>• Device type</p> <p>• Features used in application</p>
                  <p>• Logs of date/time of interaction</p>
                  <p>• Details of app crashes or failures</p>
                </td>

                <td>
                  <p className={'automatic'}>Automatically Recorded</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>Meta</p>
                </td>

                <td>
                  <p>
                    • A collection of additional Provider specific transaction
                    metadata
                  </p>
                </td>

                <td>
                  <p className={'nordigen'}>Nordigen</p>
                </td>
              </tr>
            </tbody>
          </table>
        </article>

        <article className={'article5'}>
          <h2>How Do We Use Your Data (and our Legal Basis for Processing)</h2>

          <h3>Categories of Data Processors</h3>
          <p>
            Unbanx makes use of several different categories of data processor
            to help us deliver our services.
          </p>

          <ul>
            <li>
              <h4>IT Service Providers</h4>
              <p>
                Unbanx uses a range of IT service providers to host our systems,
                develop and maintain software, and support our internal
                information management processes.
              </p>
            </li>

            <li>
              <h4>Accounting, and Finance Service Providers</h4>
              <p>
                We avail of the services of a number of third parties to support
                our accounting and payments management processes.
              </p>
            </li>

            <li>
              <h4>Data Protection Officer Services</h4>
              <p>
                Unbanx has engaged the services of an outsourced Data Protection
                Officer to support our compliance obligations.
              </p>
            </li>
          </ul>

          <h5>Categories of Recipients</h5>
          <p>
            Unbanx may provide personal data to the following categories of
            recipient:
          </p>

          <ul>
            <li>
              <p>
                We use your data to provide a Data Union for the Streamr
                Marketplace. We aggregate the collected banking data to create a
                Data Union. We push the collected data to the Streamr
                Marketplace for selling as Data Union. For every module, we send
                all the collected data to a predefined stream on the Streamr
                Network. A Data Union has been defined on Streamr Marketplace to
                aggregate these data. Buyers can subscribe to the streams of
                this product for a specific period of time by paying the
                requested cost.
              </p>
            </li>

            <li>
              <p>Data buyers on the Streamr Marketplace.</p>
            </li>

            <li>
              <p>
                To law enforcement agencies or regulatory authorities on receipt
                of a valid court order or where expressly required under
                legislation and only to the extent required by law.
              </p>
            </li>

            <li>
              <p>
                n the event of a corporate sale, merger, reorganisation, sale of
                assets, dissolution, or other business-related event, your
                information may be transferred as part of the assets of Unbanx.
              </p>
            </li>
          </ul>

          <h3>How Long Do We Keep Your Data?</h3>
          <p>
            Unbanx retains identifiable data for the duration of your use of our
            service or use of the application plus any retention period that may
            be defined by:
          </p>

          <ul>
            <li>
              <p>
                Legal requirements set out in legislation or regulatory guidance
                in jurisdictions in which our services are provided.
              </p>
            </li>

            <li>
              <p>
                Periods that are reasonably necessary for business and legal
                purposes.
              </p>
            </li>
          </ul>

          <p>
            We will also keep data which cannot directly or indirectly identify
            living individuals for analytics and research purposes to help
            improve our products and services.
          </p>

          <h3>Cross-border Data Transfers</h3>
          <p>
            Third party service providers contracted by Unbanx hold personal
            data in secure data centres inside the EEA. Where data is
            transferred outside the EU/EEA, such transfers are carried out on
            the basis of either:
          </p>

          <ul>
            <li>
              <p>Standard Contractual Clauses, or</p>
            </li>

            <li>
              <p>An adequacy decision of the European Commission.</p>
            </li>
          </ul>

          <p>
            In addition, the provision of data on Streamer network nodes may
            necessitate transfer of your personal data to Switzerland or other
            jurisdictions outside the EU/EEA with different data protection
            regimes to that which applies in Ireland or the EU.
          </p>

          <p>
            We regularly review our providers and the basis for any transfers of
            personal data outside the EEA.
          </p>

          <h3>Keeping Your Data Safe and Secure</h3>
          <p>
            We place great importance on the security of all personal data
            associated with our customers. We have security measures in place to
            attempt to protect against the loss, misuse and alteration of
            personal information under our control.
          </p>

          <p>
            Periodic reviews of our security standards are carried out and our
            software development process has a checkpoint to identify and new
            risks when we define / develop new features.
          </p>

          <p>
            However, with any electronic transmission and storage of data comes
            risks and we cannot guarantee that our databases, or those of our
            third-party affiliates, will be 100% secure. There is also a risk of
            data being intercepted while being transferred over the internet.
          </p>

          <p>In the event of a breach of data security, Unbanx will:</p>

          <ul>
            <li>
              <p>
                Where we are acting as a Data Controller, we will notify without
                undue delay the relevant Supervisory Authority where we identify
                that there is a risk to the fundamental rights and freedoms of
                people using our applications or services.
              </p>
            </li>

            <li>
              <p>
                Where we are acting as a Data Controller and we identify a high
                risk to your rights and freedoms we will notify you of the
                incident without undue delay.
              </p>
            </li>
          </ul>

          <h4>Automated Decision Making and Profiling</h4>
          <p>
            The operation of the Unbanx application requires the processing of
            data for profiling to identify usage patterns and ensure usage
            information is available.
          </p>

          <p>
            Profiling and analytics is carried on the basis of your explicit
            consent and is necessary for the performance of our your contract
            with us.
          </p>

          <p>
            No decisions are taken based solely on automated decision making
            which would have a legal or equally significant impact on a user as
            the data is used for creating insights on use of Unbanx.
          </p>

          <h3>Your Rights under GDPR</h3>
          <p>
            Where Unbanx is acting as a Data Controller, you have rights under
            EU Data Protection law. These rights are:
          </p>

          <ul>
            <li>
              <p>
                A right of access which allows you to request a copy of your
                data in an intelligible form (Article 15 GDPR)
              </p>
            </li>

            <li>
              <p>
                A right of rectification which allows you to request a
                correction of errors or inaccuracies in the data that we hold
                relating to you (Article 16 GDPR)
              </p>
            </li>

            <li>
              <p>
                A right to object to processing and to “opt-out” of having your
                data processed by us on the basis of consent, our legitimate
                interests or a public interest basis unless we can demonstrate
                compelling legitimate grounds that over-ride your interests,
                rights, and freedoms. (Article 21 GDPR)
              </p>
            </li>

            <li>
              <p>
                A right to erasure in certain specified circumstances. This
                right does not apply where we are processing data for compliance
                with a legal obligation, for reasons of public interest in
                relation to public health, for archiving purposes for historical
                or scientific research, or where necessary to establish or
                defend legal claims (Article 17)
              </p>
            </li>

            <li>
              <p>
                A right to restrict processing in certain circumstances (Article
                18)
              </p>
            </li>

            <li>
              <p>
                Under GDPR individuals also have rights to seek compensation for
                infringement of their data protection rights under the
                legislation.
              </p>
            </li>
          </ul>

          <h3>Right to Complain to the Data Protection Commission</h3>
          <p>
            You have the right to file a complaint to the Data Protection
            Commission.
          </p>

          <p>
            Their contact information can be found online at this link:{' '}
            <a
              href={'https://dataprotection.ie/en/contact/how-contact-us'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              https://dataprotection.ie/en/contact/how-contact-us
            </a>
          </p>
        </article>
      </section>

      <Footer />
    </>
  )
}

export { PrivacyPolicy }
