function Button(props) {
  const { action, kind, label } = props

  const btnClass = kind || 'light'

  return (
    <button className={btnClass} onClick={action}>
      {label}
    </button>
  )
}

export { Button }
