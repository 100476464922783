import { Nav } from '../../components/Nav'
import { Footer } from '../../components/Footer'
import { FormCTA } from '../../components/FormCTA'

import Ballons_Above_Logo from '../../images/balloons-above-logo.svg'

function Community() {
  const formSpec = {
    title: 'Sign up to our community newsletter',
    kind: 'newsletter',
    btnLabel: 'Sign up',
    style: 2,
  }

  return (
    <main>
      <div className={'nav-container'}>
        <Nav />
      </div>

      <section id={'community'}>
        <article>
          <h1>Join our Community</h1>

          <p>
            At Unbanx, we believe that personal data and its value belong to the
            people who create it.
          </p>

          <p>
            That is why we are building a platform and community where people
            can take ownership and unlock the value of their own bank
            transaction data by earning passive income from it.
          </p>

          <p>
            2022 has been a busy year for us: we are launching our app and also
            gearing up for the public sale of the $UBX token to give our
            community ownership of the project.
          </p>

          <p>
            We are on a mission to bring the benefits of web3, open banking and
            open data to the masses.
          </p>

          <h2>Join the movement.</h2>
        </article>

        <img src={Ballons_Above_Logo} alt={'Ballons above logo'} />

        <div className={'community-animation-header'}>
          <ul className="scroller-text">
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
          </ul>

          <ul className="scroller-text" aria-hidden={'true'}>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
          </ul>

          <ul className="scroller-text" aria-hidden={'true'}>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
            <li>&#x2022; JOIN THE MOVEMENT</li>
          </ul>
        </div>
      </section>

      <section id={'community-cta'}>
        <FormCTA spec={formSpec}>
          <p className={'small'}>
            By submitting your email you consent to receiving marketing updates
          </p>
        </FormCTA>
      </section>

      <div className={'white-bg'}>
        <section id={'community-litepaper'}>
          <h2>
            Read our{' '}
            <a
              href={
                'https://unbanx.notion.site/Unbanx-Litepaper-a8f9f729d89641d198ab8cc9fbb56357'
              }
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              Litepaper
            </a>
          </h2>
        </section>

        <Footer />
      </div>
    </main>
  )
}

export { Community }
