import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { List, X } from 'phosphor-react'

import { Button } from './Button'

import Logo from '../images/logo.svg'

function Nav() {
  const [navOpen, setNavOpen] = useState(false)

  let navigate = useNavigate()

  const mobileNavProps = [
    {
      label: 'Community',
      action: () => {
        navigate('/community')
      },
    },

    {
      label: 'FAQ',
      action: () => {
        navigate('/faq')
      },
    },
    {
      label: 'App',
      kind: 'app-button',
      action: () => {
        window.open('https://unbanx.app', '_blank', 'noopener noreferrer')
      },
    },
  ]

  const desktopNavProps = [
    {
      label: 'Community',
      action: () => {
        navigate('/community')
      },
    },

    {
      label: 'FAQ',
      action: () => {
        navigate('/faq')
      },
    },
    {
      label: 'WebApp',
      kind: 'app-button',
      action: () => {
        window.open('https://unbanx.app', '_blank', 'noopener noreferrer')
      },
    },
  ]

  return (
    <nav>
      <Link to={'/'}>
        <img src={Logo} alt={'Unbanx Logo'} />
      </Link>

      <button
        className={'ham-menu'}
        onClick={() => setNavOpen((prevState) => !prevState)}
      >
        {navOpen ? <X size={29} /> : <List size={29} />}
      </button>

      <ul className={navOpen ? 'mobile-list' : 'none'}>
        {mobileNavProps.map((item) => (
          <li key={item.label}>
            <Button action={item.action} label={item.label} kind={item.kind} />
          </li>
        ))}
      </ul>

      <ul className="desktop-list">
        {desktopNavProps.map((item) => (
          <li key={item.label}>
            <Button action={item.action} label={item.label} kind={item.kind} />
          </li>
        ))}
      </ul>
    </nav>
  )
}

export { Nav }
