import { useEffect, useState } from 'react'

import { Nav } from '../../components/Nav'
import { Footer } from '../../components/Footer'
import { FormCTA } from '../../components/FormCTA'
import { FormMain } from '../../components/FormMain'

import Play_Store_Banner_White from '../../images/googleplay-white.svg'
import Play_Store_Banner_Colored from '../../images/googleplay-colored.svg'
import App_Store_Banner from '../../images/appstore.svg'
import QR_Code from '../../images/qr-code.png'
import Person_Relaxing from '../../images/person-relaxing.png'
import Hand_Holding_Money from '../../images/hand-holding-money.png'
import Jagged_Left from '../../images/jagged-left.png'
import Jagged_Right from '../../images/jagged-right.png'
import Anonymous_Person from '../../images/anonymous-person.png'
import Apple_Logo from '../../images/apple-logo.svg'
import Nike_Logo from '../../images/nike-logo.svg'
import Ticketmaster_Logo from '../../images/ticketmaster-logo.svg'
import Amazon_Logo from '../../images/amazon-logo.svg'
import Uber_Logo from '../../images/uber-logo.svg'
import Deliveroo_Logo from '../../images/deliveroo-logo.svg'
import Ikea_Logo from '../../images/ikea-logo.svg'
import Adidas_Logo from '../../images/adidas-logo.svg'
import Argos_Logo from '../../images/argos-logo.svg'
import Sing_Up from '../../images/sign-up.png'
import About_Us from '../../images/about-us.png'

function SlideContent1() {
  return (
    <>
      <h3>It's free</h3>

      <div className={'slide-content'}>
        <div className={'slide-content-text'}>
          Unbanx is a free app that helps you profit from your own banking data.
        </div>
        <img src={Person_Relaxing} alt={'Person relaxing'} />
      </div>
    </>
  )
}

function SlideContent2() {
  return (
    <>
      <h3>Why?</h3>

      <div className={'slide-content'}>
        <div className={'slide-content-text'}>
          Banks are already selling your financial data. They have been for
          years. We believe it's time to take back some control.
        </div>
        <img src={Hand_Holding_Money} alt={'Hand holding money'} />
      </div>
    </>
  )
}

function SlideContent3() {
  return (
    <>
      <h3>Private</h3>

      <div className={'slide-content'}>
        <div className={'slide-content-text'}>
          Our reassuringly anonymous data means you collect rewards without ever
          compromising your security.
        </div>
        <img src={Anonymous_Person} alt={'Anonymous person'} />
      </div>
    </>
  )
}

let currentCmpIndex = 0

function Home() {
  const [slideContent, setSlideContent] = useState({
    contentList: [<SlideContent1 />, <SlideContent2 />, <SlideContent3 />],
  })

  useEffect(() => {
    const updateSlide = () => {
      if (currentCmpIndex < slideContent.contentList.length - 1) {
        currentCmpIndex++
        setSlideContent({
          contentList: [
            <SlideContent1 />,
            <SlideContent2 />,
            <SlideContent3 />,
          ],
        })
      } else {
        currentCmpIndex = 0
        setSlideContent({
          contentList: [
            <SlideContent1 />,
            <SlideContent2 />,
            <SlideContent3 />,
          ],
        })
      }
    }

    const timeoutId = window.setTimeout(() => {
      updateSlide()
    }, 5000)

    return () => window.clearTimeout(timeoutId)
  }, [slideContent])

  const selectCurrentSlide = (index) => {
    currentCmpIndex = index
    setSlideContent({
      contentList: [<SlideContent1 />, <SlideContent2 />, <SlideContent3 />],
    })
  }

  const formSpec1 = {
    title: 'Join the waitlist',
    kind: 'waitlist',
    btnLabel: 'JOIN',
    style: 1,
  }

  const formSpec2 = {
    title: 'Join the waitlist',
    btnLabel: 'JOIN',
    kind: 'waitlist',
    style: 1,
  }

  const seeLitePaper = () => {
    window.open(
      'https://unbanx.notion.site/Unbanx-Litepaper-a8f9f729d89641d198ab8cc9fbb56357',
      '_blank',
      'noopener noreferrer'
    )
  }

  return (
    <main>
      <div className={'nav-container'}>
        <Nav />
      </div>

      <div className={'azure-bg min-padding'}>
        <section id={'intro'}>
          <h2>Get what you’re owed</h2>

          <h3>Banks already sell your data, why not get your share?</h3>

          <iframe
            title={'Introduction to Unbanx'}
            src="https://player.vimeo.com/video/759575266?h=a209ff9e38&portrait=0&byline=0&title=false"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </section>
      </div>

      <div className={'white-bg'}>
        <section id={'join'}>
          <div className={'join-form-header'}>
            <ul className="scroller-text">
              <li>&#x2022; UNBANX IS IN BETA, JOIN THE WAITLIST NOW</li>
              <li>&#x2022; UNBANX IS IN BETA, JOIN THE WAITLIST NOW</li>
              <li>&#x2022; UNBANX IS IN BETA, JOIN THE WAITLIST NOW</li>
              <li>&#x2022; UNBANX IS IN BETA, JOIN THE WAITLIST NOW</li>
              <li>&#x2022; UNBANX IS IN BETA, JOIN THE WAITLIST NOW </li>
            </ul>

            <ul className="scroller-text" aria-hidden={'true'}>
              <li>&#x2022; UNBANX IS IN BETA, JOIN THE WAITLIST NOW</li>
              <li>&#x2022; UNBANX IS IN BETA, JOIN THE WAITLIST NOW</li>
              <li>&#x2022; UNBANX IS IN BETA, JOIN THE WAITLIST NOW</li>
              <li>&#x2022; UNBANX IS IN BETA, JOIN THE WAITLIST NOW</li>
              <li>&#x2022; UNBANX IS IN BETA, JOIN THE WAITLIST NOW </li>
            </ul>
          </div>

          <FormMain spec={formSpec1} />
        </section>
      </div>

      <div className={'black-bg'}>
        <section id={'links'}>
          <img
            className={'mobile-jagged-left'}
            src={Jagged_Left}
            alt={'Black jagged figure'}
          />
          <img
            className={'mobile-jagged-right'}
            src={Jagged_Right}
            alt={'Black jagged figure'}
          />
          <h2>Already have an invite code?</h2>

          <p className={'qr-code'}>Scan the QR Code to download the app</p>
          <p className={'download'}>Download the app</p>

          <img className={'qr-code'} src={QR_Code} alt={'QR code'} />

          <div className={'app-links'}>
            <a
              href={'https://apps.apple.com/app/unbanx/id1641694180'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <img src={App_Store_Banner} alt={'App store banner'} />
            </a>

            <a
              href={'https://play.google.com/store/apps/details?id=com.rn01'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <img
                className={'play-store-mobile'}
                src={Play_Store_Banner_Colored}
                alt={'Play store banner'}
              />
              <img
                className={'play-store-desktop'}
                src={Play_Store_Banner_White}
                alt={'Play store banner'}
              />
            </a>
          </div>
        </section>
      </div>

      <div className={'white-bg'}>
        <section id={'earn-redeem'}>
          <div className={'slide-container'}>
            <div className={'index-btns'}>
              <button
                onClick={() => selectCurrentSlide(0)}
                className={'slide-mobile-btn'}
              />

              <button
                onClick={() => selectCurrentSlide(1)}
                className={'slide-mobile-btn'}
              />

              <button
                onClick={() => selectCurrentSlide(2)}
                className={'slide-mobile-btn'}
              />

              <button
                onClick={() => selectCurrentSlide(0)}
                className={
                  currentCmpIndex === 0
                    ? 'slide-btn active'
                    : 'slide-btn inactive'
                }
              >
                1
              </button>

              <button
                onClick={() => selectCurrentSlide(1)}
                className={
                  currentCmpIndex === 1
                    ? 'slide-btn active'
                    : 'slide-btn inactive'
                }
              >
                2
              </button>

              <button
                onClick={() => selectCurrentSlide(2)}
                className={
                  currentCmpIndex === 2
                    ? 'slide-btn active'
                    : 'slide-btn inactive'
                }
              >
                3
              </button>
            </div>

            <div className={'slide-content-container'}>
              {slideContent.contentList[currentCmpIndex]}
            </div>
          </div>
        </section>
      </div>

      <div className={'azure-bg'}>
        <section id={'brands'}>
          <h2>Redeem your earnings with top brands</h2>

          <div className="scroller-brands">
            <ul className={'animation-items-container'}>
              <li>
                <img src={Apple_Logo} alt={'Apple logo'} />
              </li>
              <li>
                <img src={Nike_Logo} alt={'Nike logo'} />
              </li>
              <li>
                <img src={Ticketmaster_Logo} alt={'Ticketmaster logo'} />
              </li>
              <li>
                <img src={Amazon_Logo} alt={'Amazon logo'} />
              </li>
              <li>
                <img src={Uber_Logo} alt={'Uber logo'} />
              </li>
              <li>
                <img src={Deliveroo_Logo} alt={'Deliveroo logo'} />
              </li>
              <li>
                <img src={Ikea_Logo} alt={'Ikea logo'} />
              </li>
              <li>
                <img src={Adidas_Logo} alt={'Adidas logo'} />
              </li>
              <li>
                <img src={Argos_Logo} alt={'Argos logo'} />
              </li>
            </ul>

            <ul className={'animation-items-container'} aria-hidden={'true'}>
              <li>
                <img src={Apple_Logo} alt={'Apple logo'} />
              </li>
              <li>
                <img src={Nike_Logo} alt={'Nike logo'} />
              </li>
              <li>
                <img src={Ticketmaster_Logo} alt={'Ticketmaster logo'} />
              </li>
              <li>
                <img src={Amazon_Logo} alt={'Amazon logo'} />
              </li>
              <li>
                <img src={Uber_Logo} alt={'Uber logo'} />
              </li>
              <li>
                <img src={Deliveroo_Logo} alt={'Deliveroo logo'} />
              </li>
              <li>
                <img src={Ikea_Logo} alt={'Ikea logo'} />
              </li>
              <li>
                <img src={Adidas_Logo} alt={'Adidas logo'} />
              </li>
              <li>
                <img src={Argos_Logo} alt={'Argos logo'} />
              </li>
            </ul>
          </div>
        </section>
      </div>

      <div className={'white-bg'}>
        <section id={'signup'}>
          <h2>Signup in under 2 minutes</h2>

          <ol>
            <li>
              <h3>Download the app or use the web app and register via text</h3>
            </li>
            <li>
              <h3>
                Complete your profile and securely connect your bank account
              </h3>
            </li>
            <li>
              <h3>Sit back, earn your points and redeem them for rewards</h3>
            </li>
          </ol>

          <img
            className={'earn-phones'}
            src={Sing_Up}
            alt={'Person next the phone '}
          />

          <FormCTA spec={formSpec2} />
        </section>
      </div>

      <div className={'azure-bg'}>
        <section id={'about_us'}>
          <h2>We are building the world's first consumer banking data union</h2>

          <button onClick={() => seeLitePaper()}>LEARN MORE</button>

          <img src={About_Us} alt={'Person with a credit card'} />
        </section>
      </div>

      <div className={'white-bg'}>
        <Footer />
      </div>
    </main>
  )
}

export { Home }
