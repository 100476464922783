import { Nav } from '../../components/Nav'
import { Footer } from '../../components/Footer'
import { Explainer } from '../../components/Explainer'

function FAQ() {
  const faqList = [
    {
      question: 'Why are banks selling data?',
      answer:
        'Banks have joined the likes of big tech companies such as Google' +
        'and Facebook in profiting from your personal information. ' +
        'Your banking transaction data when pooled together with others’ is highly valuable.',
    },
    {
      question: 'What data are they selling and why is it valuable?',
      answer:
        'Banking transaction data include information on what you spend your money on. ' +
        'It is one of the most valuable forms of personal data because it provides action over sentiment.',
    },
    {
      question: 'How is Unbanx different?',
      answer:
        'Unbanx believes that the people who create data should own it, and' +
        ' should therefore be the ones who profit from it when it’s sold. ' +
        'We provide the platform to do this for your banking data, distributing 80% ' +
        'of the revenue made from your data back to you.',
    },
    {
      question: 'What are Unbanx Points?',
      answer:
        'Unbanx points are how we reward Unbanx members.' +
        'Think of them as a points system or a currency. You can redeem them for products ' +
        'and services from some of the biggest brands.',
    },
    {
      question: 'How is my data secure and safe with Unbanx?',
      answer:
        'Unbanx uses industry leading encryption to ensure all customer data' +
        ' is secure and untraceable. We never ask for your name and ensure your' +
        ' data is always anonymous, which means you collect your rewards without' +
        ' ever compromising your privacy.',
    },
  ]

  return (
    <>
      <div className={'nav-container'}>
        <Nav />
      </div>

      <div className={'cultured-bg'}>
        <section id={'faq'}>
          <h1>Frequently Asked Questions</h1>

          <div className={'questions'}>
            {faqList.map((item) => (
              <Explainer key={item.question} explainerSpec={item} />
            ))}
          </div>

          <div className={'more-questions'}>
            <h3>More questions?</h3>

            <h4>
              <a href={'mailto:hello@unbanx.me'}>Send us a question</a> and
              we’ll email you back.
            </h4>
          </div>
        </section>
      </div>

      <Footer />
    </>
  )
}

export { FAQ }
