import { useNavigate } from 'react-router-dom'
import { EnvelopeSimple, LinkedinLogo, TwitterLogo } from 'phosphor-react'

import Logo from '../images/logo.svg'
import Telegram_Logo from '../images/telegram-logo.png'

import { Button } from './Button'

function Footer() {
  let navigate = useNavigate()

  const footerProps = [
    {
      label: 'Privacy Policy',
      action: () => {
        navigate('/privacy-policy')
      },
    },
  ]

  return (
    <footer>
      <div className={'top'}>
        <img id={'logo'} className={'logo'} src={Logo} alt={'Unbanx Logo'} />

        <div className={'contact'}>
          <a href={'mailto:hello@unbanx.me'}>
            <EnvelopeSimple size={42} color={'var(--c2)'} />
          </a>

          <a
            href="https://twitter.com/Unbanx_app"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterLogo size={42} weight="fill" color={'var(--c2)'} />
          </a>

          <a href="https://t.me/unbanx" target="_blank" rel="noreferrer">
            <img
              id={'telegram-logo'}
              src={Telegram_Logo}
              alt={'Telegram Logo'}
            />
          </a>

          <a
            href="https://www.linkedin.com/company/unbanx"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinLogo size={42} weight="fill" color={'var(--c2)'} />
          </a>
        </div>
      </div>

      <div className={'down'}>
        <p>©UNBANX</p>

        <ul>
          {footerProps.map((item) => (
            <li key={item.label}>
              <Button action={item.action} label={item.label} />
            </li>
          ))}
        </ul>
      </div>
    </footer>
  )
}

export { Footer }
