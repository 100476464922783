import MailchimpSubscribe from 'react-mailchimp-subscribe'

const urlNewsLetter =
  'https://unbanx.us7.list-manage.com/subscribe/post?u=a4891baf3302c651e71d6a3b3&amp;id=0ea9a880de&amp;f_id=0060eae3f0'
const urlWaitlist =
  'https://unbanx.us7.list-manage.com/subscribe/post?u=a4891baf3302c651e71d6a3b3&amp;id=2ccb9ba473&amp;f_id=00e4f7e3f0'

let email

function CustomForm1({ status, message, onValidated, btnLabel }) {
  const submit = (e) => {
    e.preventDefault()
    email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
      })
  }

  return (
    <form className={'waitlist'} onSubmit={submit}>
      {status === 'sending' && (
        <div
          style={{
            color: 'var(--c3)',
            fontFamily: 'var(--fn0r)',
            fontSize: 'var(--s4)',
          }}
        >
          sending...
        </div>
      )}

      {status === 'error' && (
        <div
          style={{
            color: 'red',
            fontFamily: 'var(--fn0r)',
            fontSize: 'var(--s4)',
          }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {status === 'success' && (
        <div
          style={{
            color: 'var(--c3)',
            fontFamily: 'var(--fn0r)',
            fontSize: 'var(--s4)',
          }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      <div className={'form-content'}>
        <input
          className={'form-content-input'}
          ref={(node) => (email = node)}
          type="email"
          name={'email'}
          placeholder="EMAIL"
        />
        <p className={'small'}>
          By submitting your email you consent to receiving marketing updates
        </p>
      </div>

      <div className={'form-btn-container'}>
        <button type={'submit'} className={'mobile-btn'}>
          {btnLabel ? btnLabel : <p>JOIN THE WAITLIST</p>}
        </button>

        <button type={'submit'} className={'desktop-btn'}>
          {btnLabel ? btnLabel : <p>JOIN</p>}
        </button>
      </div>
    </form>
  )
}

function CustomForm2({ status, message, onValidated, btnLabel }) {
  const submit = (e) => {
    e.preventDefault()
    email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
      })
  }
  return (
    <form onSubmit={submit}>
      {status === 'sending' && (
        <div
          style={{
            color: 'var(--c3)',
            fontFamily: 'var(--fn0r)',
            fontSize: 'var(--s4)',
          }}
        >
          sending...
        </div>
      )}

      {status === 'error' && (
        <div
          style={{
            color: 'red',
            fontFamily: 'var(--fn0r)',
            fontSize: 'var(--s4)',
          }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {status === 'success' && (
        <div
          style={{
            color: 'var(--c3)',
            fontFamily: 'var(--fn0r)',
            fontSize: 'var(--s4)',
          }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      <input
        style={{
          fontSize: 'var(--s4)',
          backgroundColor: 'var(--c6)',
          borderRadius: 'var(--s1)',
          border: '1px solid var(--c9)',
        }}
        ref={(node) => (email = node)}
        type="email"
        name={'email'}
        placeholder="Enter your email address"
      />

      <br />

      <button
        type={'submit'}
        style={{
          background: '#191919',
          color: '#FFFFFF',
          fontSize: 'var(--s4)',
          borderRadius: 'var(--s1)',
          border: 'none',
          width: '26%',
        }}
      >
        {btnLabel}
      </button>
    </form>
  )
}

function FormMain(props) {
  const { title, kind, btnLabel, style } = props.spec

  const url = kind === 'waitlist' ? urlWaitlist : urlNewsLetter

  return (
    <>
      {style === 1 ? (
        <div className={'form-container form-container1'}>
          <h4 className={'style1'}>{title}</h4>

          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm1
                status={status}
                message={message}
                btnLabel={btnLabel}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </div>
      ) : (
        <div className={'form-container form-container2'}>
          <h4 className={'style2'}>{title}</h4>

          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm2
                status={status}
                message={message}
                btnLabel={btnLabel}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />

          {props.children}
        </div>
      )}
    </>
  )
}

export { FormMain }
