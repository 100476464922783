import './App.css'

import { Home } from './feat/home/Home'
import { Route, Routes } from 'react-router'
import { Community } from './feat/community/Community'
import { FAQ } from './feat/faq/FAQ'
import { PrivacyPolicy } from './feat/privacy-policy/PrivacyPolicy'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/community" element={<Community />} />

      <Route path="/faq" element={<FAQ />} />

      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  )
}

export default App
